body {
  margin: 0;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} 

/* @font-face {
  font-family: 'myFont';
  src: url('./fonts/OPlusSans3-ExtraLight.ttf');
} */

/* body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
} 
@import "./Home/myCss.css";*/