
.site-custom-tab-bar {
    z-index: 1;
    background: #3f8cff;
}

/* .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active::before {
    border-top: 2px solid blue;
} */

/* .tab_pane {
    border: solid 1px;
    border-radius: 10px;
    border-color: #ffffff;
    background-color: #ffffff;
    padding: 6px 15px;
    font-weight: 550;
    opacity: 0.6
}

.tab_pane_active {
    border: solid 1px;
    border-radius: 10px;
    border-color: #3f8cff;
    background-color: #ffffff;
    padding: 6px 15px;
    font-weight: 550;
    opacity: 0.6
} */

/* .ant-tabs-tab-active {
    border-radius: 10px;
    border: 1px solid ;
    border-color: #3f8cff;
    padding: -10px -15px !important;
} */

.add_title{
    text-align: left;
    margin-top: 50px;
}

.add_divide{
    border-top: 1px solid #AAAAAA;
}

.PublishArticle-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}

.PublishArticle-wrap .ant-form-item{
    padding: 0 30px;
}

.PublishArticle-wrap .ant-col{
    text-align: left;
}

.divfj {
    border: 1px solid #c1c1c1;
    width: 100%;
    height: 260px;
    border-radius: 7.75px;
}

.sbutt {
    background-color: #dddddd;
    width: 100%;
    height: 40px;
    display: block;
    border-radius: 7px 7px 0 0;
    border: 2px solid rgb(164, 164, 164);
    padding-top: 6px;
}

.divfj-xy {
    margin-top: 35px;
    border-left: 1px solid #c1c1c1;
    width: 33.33%;
    height: 80%;
    float: left;
}

.divfj-xy-1 {
    /* border: 1px solid red; */
    width: 54px;
    height: 65px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
}

.divfj-xy-2 {
    width: 100%;
    height: 16px;
    text-align: center;
    margin-top: 15px;
    line-height: 14px;
    font-size: 16px;
    font-weight: 500;
}

.divfj-xy-3 {
    width: 100%;
    height: 16px;
    text-align: center;
    margin-top: 19px;
    line-height: 14px;
    color: #c1c1c1;
    font-size: 12px;
}

.divfj-xy-4 {
    width: 100%;
    height: 16px;
    text-align: center;
    margin-top: 6px;
    line-height: 14px;
    color: #c1c1c1;
    font-size: 12px;
}

.hfoo {
    width: 100%;
    text-align: center;
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 11px;
}

.hfoo .ant-checkbox-inner
{
    top: -2px;
    width: 13px;
    height: 13px;
}

.group-sub {
    width: 510px;
    padding-left: 50px;
    margin: 25px auto;
}

.group-sub input {
    width: 120px;
    height: 35px;
    color: white;
    border-radius: 5px;
    margin-right: 30px;
    border: 0px solid rgb(152,100,201);
}

.nav-sub-do {
    background-color: rgb(152,100,201);
}

.nav-sub-no {
    background-color: #c1c1c1;
}

.ant-upload-list{
    display: none;
}

.ant-progress-status-success .ant-progress-bg {
    background-color: rgb(164, 8, 15) !important;
}

.divfj .ant-progress-bg{
    height: 12px !important;
}

.thumbnail {
    display: block;
    padding: 4px 20px;
    margin-bottom: 8px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    -webkit-transition: border 0.2s ease-in-out;
    -o-transition: border 0.2s ease-in-out;
    transition: border 0.2s ease-in-out;
}

.plupload-preview a img {
    height: 80px;
    object-fit: cover;
}
.thumbnail > img, .thumbnail a > img {
    margin-left: auto;
    margin-right: auto;
}
.img-responsive, .thumbnail > img, .thumbnail a > img, .carousel-inner > .item > img, .carousel-inner > .item > a > img {
    display: block;
    max-width: 100%;
    height: auto;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}

.side-notice {
    position: fixed;
    right: 0;
    top: 20%;
    display: block;
    background-color: #ffd;
    z-index: 9999;
}
.side-notice1 {
    position: fixed;
    right: 0;
    top: 80%;
    display: block;
    background-color: #ffd;
    z-index: 9999;
}
.side-btn {
    float: left;
    width: 30px;
    padding: 20px 10px;
    display: block;
    margin-left: -30px;
    border-radius: 7px 0 0 7px;
    background-color: #ffb;
}

.side-content {
    float: left;
    display: none;
    width: 300px;
    padding: 20px;
    text-align: left;
}

.PublishArticle-wrap .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    display: none;
}

.not-allowed{
    cursor: not-allowed !important;
}
