
.detail_subtitle {
    text-align: left;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    height:30px;
    line-height: 30px;
}
.detail_subtitle span {
    position:absolute;
    font-size:14px;
    font-weight: normal;
    margin-left:80px;
}
.offerContent {
    margin-top: 10px;
    text-align: left;
    width: 100%;
    line-height: 1.9;
    color: #777;
    overflow-wrap: break-word;
}
.offerContent img{
    max-width: 100%;
}
.nopdf {
    text-align: center;
    padding: 30px;
    border-radius: 12px;
    background: #ddd;
    font-size: 23px;
}
.divPdf{
    width: 135px;
    height: 139px;
    background: url("../../../img/pdf-r.png") no-repeat center;
    background-size: 100%;
    float: left;
}
.divImg{
    width: 25%;
    height: 100px;
    float: left;
    padding: 10px;
}

.hh{
    border-bottom: 1px solid #AAAAAA;
    width: 100%;
    padding-bottom: 10px;
    margin-top: 30px;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
}
.hh3{margin: 20px 0;font-size: 18px;text-align: left;}
.sanjiao-img{
    margin-bottom: 50px;
    /* height: 500px; */
    background-size: 100% 100%;
    margin-top: 10px;
}

.clear{
    clear: both;
}

.attach_pic {
    max-height: 500px;
    max-width: 100%;
    display: none;
}
.attach_picbtn {
    width:10px;
    height:10px;
    border-radius:10px;
    border:1px solid #e9e9e9;
    margin:5px;
    cursor: pointer;
}
