.top-right{
    /* position: fixed; */
    margin-top: 4px;
    float: right;
    margin-right: -20px;
    height: 70%;
    padding: 10px 20px;
    border-radius: 10px;
    display:flex;justify-content: center; align-items:center;
  }

.userSuper{
    /* position: fixed; */
    margin-left: 20px;
    margin-top: 8px;
    margin-right: -20px;
    /* background-color: white; */
    height: 42px;
    padding: 10px 20px;
    border-radius: 10px;
    display:flex;justify-content: center; align-items:center;
    color: white;
  }


.msgbutton{
  /* position: fixed; */
  border-radius:10px;
  background-color:"#fff";
  height:42px;
  margin-top:9px
}

.ant-modal-content{
  border-radius: 8px;
  /* padding-top: 10px; */
}

/* 设置滚动条的样式 */
/* ::-webkit-scrollbar {
width:5px;
display: none;
} */
/* 滚动槽 */
/* ::-webkit-scrollbar-track {
-webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
border-radius:10px;
} */
/* 滚动条滑块 */
/* ::-webkit-scrollbar-thumb {
border-radius:10px;
background:rgba(0,0,0,0.1);
-webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
background:rgba(0,0,0,0.1);
} */


.ant-dropdown-menu-item-active {
  color: #3f8cff;
  background-color: rgb(236, 243, 255) !important;
}
/* 
.ant-select-item-option-active {
  color: #3f8cff;
  background-color: rgb(230, 247, 255) !important;
} */

.ant-form-item-label > label{
  color: rgba(0, 0, 0, 0.6);
}

.ant-radio-button-wrapper{
  border-radius: 8px !important;
  background: rgb(234,234,234) !important;
  border-color: rgb(234,234,234) !important;

}
.ant-radio-button-wrapper-checked {
  border-radius: 8px !important;
  background: rgb(232,241,255) !important;
  border-color: rgb(232,241,255) !important;
}

/* .ant-modal-body {
  padding: 24px 40px;
} */

/* .ant-modal-footer {
  padding: 5px 20px 30px;
  border-top: 0px;
} */

.site-layout-content {
  min-height: 280px;
  padding: 24px 30px;
  background: #fff;
  border-radius: 18px;
}
/* #components-layout-demo-top .logo { */
.layout .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: -5px 24px 16px 0;
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.ant-layout-header{
  background-color: white;
}

.layout .ant-menu-item-selected{
  background-color: rgb(152,100,201) !important;
    color: #ffffff !important;
    cursor: pointer;
}

.layout .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after{
  border-bottom: none;
}

.layout .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active{
  color: rgb(152,100,201);
}

.layout .ant-menu-submenu-title{
  color: rgb(152,100,201);
}

.layout .ant-menu-overflow-item{
  color: rgb(152,100,201);
}

.top-nav-img {
  width: 64%;
  height: 100%;
  /* border: 1px solid green; */
  /* background-image: url(/assets/img/dhajuebdladjahj.png); */
  /* background-size: 46%; */
  background-repeat: no-repeat;
  background-position: right;
  /* opacity: 0.4; */
  /* float: left; */
}

.layout{
  background: transparent;
}

.layout .ant-layout-footer{
  background-color: transparent;
  padding-bottom: 0;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgb(152,100,201);
}

.ant-modal-close-x {
  /* display: block; */
  /* width: 100%; */
  /* height: 100%; */
  font-size: 13px;
  /* font-style: normal; */
  /* line-height: 22; */
  /* text-align: center; */
  /* text-transform: none; */
  /* text-rendering: auto; */
  margin-top: -7px;
  /* color: white; */
}
/* .ant-modal-title {
  color: white;
} */
.ant-modal-header {
  padding: 10px 20px;
  color: #fff !important;
  /* background: rgb(44,62,80); */
  /* border-bottom: 1px solid #f0f0f0; */
  border-radius: 8px 8px 0 0;
}

.user-img {
  border: 2px solid #ffffff;
  width: 68px;
  height: 68px;
  border-radius: 7px;
  margin: 0 auto;
  position: relative;
  top: 25%;
  margin-left: 36px;
}
