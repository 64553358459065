/* @import '~antd/dist/antd.css'; */
/* @import '~antd/es/style/themes/default.less'; */
body{
  background-color: #f1f6fa;
  /* background: linear-gradient(to bottom,#b3423c,#dc7174); */
  /* background-color: transparent; */
}
.App {
  background-color: transparent;
  text-align: center;
  /* padding-bottom: 30px; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.titleClass{
  width:100px;
  min-height: 30px;
  margin-bottom:10px;
  font-size:24px;
  font-weight:600;
  font-family:"Microsoft Yahei";
}
.selectClient{
  text-align: right;
  padding-right: 10px;
  font-size: 17px;
}
.ant-btn{
  border-radius:10px;
  /* box-shadow: 0px 6px 12px 0px #3F8CFF43; */
}

/* 
.m-btn-white{
  color: #3f8cff;
  border-color:white;
} */
/* 
.ant-upload-list-item {
  position: relative;
  height: 18.858px;
  margin-top: 8px;
  font-size: 12px;
  display: none !important;
}

.ant-progress-status-success .ant-progress-bg {
  background-color: rgb(63,140,255);
}

.ant-progress-text{
  color: rgb(63,140,255);
}

.ant-progress-status-success .ant-progress-text{
  color: rgb(63,140,255);
}

.ant-pro-table-search {
  background-color: transparent !important;
}

.ant-pro-form-light-filter-item {
  background-color: white;
} */

.m_input{
  border-bottom: 1px solid #b1b1b1;
  font-size: 14px;
}

.ant-pro-card {
  background-color: white !important;
  padding-left: 15px;
  padding-right: 15px;
}
